import React from 'react';
import "../scss/main.scss"
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import StartWork from '../components/forms/StartWork';
import Portfolio from '../components/portfolio/Portfolio'

function PortfolioPage(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Портфолио студии разработки сайтов Androsov Digital</title>
                <meta name="description" content="Компания создающая сайты которые работают на ваш бизнес, а не против него" />
            </Helmet>
            <Header/>
            <Main>
                
                <Portfolio h1 items={[
                            {
                              org:"Business Media",
                              desc:"Доработка сайта для компании по размещению рекламы на медиафасадах. Для нас это не профильный проект на платформе megagroup.",
                              img:"../../imgs/bmrekl_portfolioo_ti.png",
                              link:"https://bmreklama.ru"
                            },
                            {
                              org:"Звони Джобсу",
                              desc:"Этот сайт мы разрабатывали в 2015 году для сервисного центра Звони Джобсу. К сожалению сейчас сайт уже не работает, но не рассказать о нем мы не можем!",
                              img:"../../imgs/calljobs_portfolioo_.png",
                              link:"https://звониджобсу.рф"
                            },
                            {
                              org:"Dexen",
                              desc:"Сайт для отличного производителя пластиковых окно. Сайт разработан на CMS Wordpress ",
                              img:"../../imgs/dexen_port_tilda.png",
                              link:"https://dexen-ru.com"
                            },
                          ]}>Наши работы</Portfolio>
            </Main>
            <Footer/>
        </>
    );
}

export default PortfolioPage;