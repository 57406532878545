import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'







function PortfolioItem({index, item}) {

    return (
        <article>
           <a href={item.link ? item.link : "#"} target="_blank" className={"portfolio__item portfolio__item_"+index}>
                <div className="portfolio__info">
                    <h3>{item.org}</h3>
                    <p>{item.desc}</p>
                </div>
                <div className="portfolio__image">
                   <img src={item.img} loading="lazy"/>
                </div>
           </a>
        </article>
    );
}



export default PortfolioItem;
//<StaticImage src={item.img} placeholder="blurred" alt=""/>