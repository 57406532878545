import React from 'react';
import PortfolioItem from './PortfolioItem';

import SectionHeader from "../Molecules/SectionHeader";


function Portfolio({items, children, h1, desc}) {

    const portfolio = items.map((item,index) => {
        return (
            <PortfolioItem index={index} item={item}/>
        )
    })
    return (
        <article className="site_section portfolio__parent-section">
            <section className="container ">
                   {h1 && <div className="article__header"><h1>{children}</h1></div>}
                   {!h1 && <SectionHeader h2 bigText={desc}>{children}</SectionHeader>}


                <div className="portfolio col-3">
                    {portfolio}
                </div>
            </section>
        </article>
    );
}

export default Portfolio;